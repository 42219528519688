<template>
  <el-menu v-if="!store.hideMenu" class="el-menu-demo" mode="horizontal" @select="handleSelect">
    <template v-if="store.user">
      <el-menu-item index="/course-list">我的课程</el-menu-item>
      <el-menu-item index="/exam-list">我的考试</el-menu-item>
      <el-menu-item index="/credits">我的学分</el-menu-item>
      <el-menu-item index="/cert">我的证书</el-menu-item>
      <el-menu-item index="/classhour">学时认证</el-menu-item>
      <el-menu-item index="/notice-list">公告</el-menu-item>
      <el-menu-item index="/archive">我的档案</el-menu-item>
      <el-menu-item index="/logout">退出登录</el-menu-item>
    </template>
    <template v-if="!store.user">
      <el-menu-item index="/login">登录</el-menu-item>
      <el-menu-item index="/register">注册</el-menu-item>
    </template>
  </el-menu>
  <router-view></router-view>
</template>

<script>
import { clearLoginInfo } from '@/utils'
import store from '@/store/simpstore'
export default {
  data() {
    return {
      store: store.state
    };
  },
  created: function () {
    let token = this.$cookie.get('token')
    store.setUser(token ? true : false)
  },
  methods: {
    handleSelect(key) {
      if(key=='/logout'){
        clearLoginInfo()
        this.$router.push({ name: 'Login' })
        store.clearUser()
        return
      }
      console.log(key)
      this.$router.push(key)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
