// import Vue from 'vue'
// import store from '@/store'
import cookie from 'js-cookie'

/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  cookie.remove('token')
    // store.commit('resetStore')
}