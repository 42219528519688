import { createRouter, createWebHistory } from 'vue-router'
import cookie from 'js-cookie'
import { clearLoginInfo } from '@/utils'

// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require('./import-' + process.env.NODE_ENV)

const routes = [
  { path: '/', name: 'main', redirect: { name: 'CourseList' } },
  { path: '/login',name: 'Login',component: _import('login/login') },
  { path: '/course-list',name: 'CourseList',component: _import('course/course-list') },
  { path: '/register',name: 'register',component: _import('login/register') },
  { path: '/video/:id',name: 'Video',component: _import('course/video') },
  { path: '/exam-list',name: 'ExamList',component: _import('exam/exam-list') },
  { path: '/exam/:id',name: 'ExamDetail',component: _import('exam/exam-detail') },
  { path: '/examine/:id',name: 'Examine',component: _import('exam/examine') },
  { path: '/notice-list',name: 'notice-list',component: _import('notice/notice-list') },
  { path: '/notice/:id',name: 'notice',component: _import('notice/notice') },
  { path: '/credits',name: 'credits',component: _import('me/credits') },
  { path: '/cert',name: 'cert',component: _import('me/cert') },
  { path: '/archive',name: 'archive',component: _import('me/archive') },
  { path: '/public-credits/:id',name: 'public-credits',component: _import('public/public-credits') },
  { path: '/classhour',name: 'classhour',component: _import('classhour/classhour-list') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // var userAgent = navigator.userAgent;
  let token = cookie.get('token')
  // if(!(userAgent.indexOf("Chrome") > -1)){
  //    return next({ name: 'Login' })
  // }
  console.log(to)
  if (!/(Login|register|public-credits)/.test(to.name) && !token ) {
    clearLoginInfo()
    next({ name: 'Login' })
  } else {
    next()
  }
  
})

export default router
