import http from '@/utils/httpRequest'

let apiBaseUrl
apiBaseUrl = process.env.VUE_APP_API_BASE_URL

const net = {}

net.apiBaseUrl = apiBaseUrl





net.listClasshour = data => {
  return post('generator/classhour/list', data)
}

net.updateClasshour = data => {
  return post('generator/classhour/update', data)
}

net.addClasshour = data => {
  return post('generator/classhour/save', data)
}


net.commonUpload = data => {
  return post('generator/classhour/classhourupload', data)
}

net.register = data => {
  return post('register', data)
}

net.login = data => {
  return post('login', data)
}

net.loginforqr = () => {
  return get('loginforqr')
}

net.loginforqr4 = s => {
  return get('loginforqr4/' + s)
}

net.userUpdate = data => {
  return post('userUpdate', data)
}

net.courseList = (type) => {
  return get('course/list?type=' + type)
}

net.videoList = (id) => {
  return get('course/' + id + '/videolist')
}

net.updateVideoRecord = (data) => {
  return post('course/updateVideoRecord', data)
}

net.examState = () => {
  return get('exam/state')
}

net.examList = () => {
  return get('exam/list')
}

net.examStart = () => {
  return get('exam/start')
}

net.examSubmit = (data) => {
  return post('exam/submit', data)
}

net.exam = (id) => {
  return get('exam/' + id)
}

net.examUpdateTime = (data) => {
  return post('exam/updateTime', data)
}

net.examLast = () => {
  return get('exam/last')
}

net.questionnaireList = () => {
  return get('questionnaire/list')
}

net.questionnaireSubmit = (data) => {
  return post('questionnaire/submit', data)
}

net.noticeList = () => {
  return get('notice/list')
}

net.userInfo = () => {
  return get('userInfo')
}

net.certUrl = () => {
  return get('certUrl')
}

net.userCreditsInfo = (id) => {
  return get('userCreditsInfo/' + id)
}

net.buildCert = () => {
  return get('buildCert')
}

function post (api, data) {
  return http.post(apiBaseUrl + api, data)
}
function get (api) {
  return http.get(apiBaseUrl + api)
}

export default net
