import { reactive } from 'vue'
export default {
  state: reactive({
      user: null,
      hideMenu : false
  }),
  setUser(user){
    this.state.user = user
  },
  hideMenu(){
    this.state.hideMenu = true
  },
  clearUser(){
    this.state.user = null
  },
  getUser(){
    return this.state.user
  }
}